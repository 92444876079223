/**
* use component name as prefix and before class name and separate it using '-'
* Example ".my-component-classname"
*/

@import "../../assets/styles/partials/variables";

.custom-info {
  font-family: $font-family;
  font-size: 18px;
  line-height: 25px;

  h5 {
    span.title {
      color: $midnight-blue;
      padding-right: 5px;
    }
  }
}
