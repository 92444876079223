@import '../partials/variables';

@mixin footer-social {
  display: inline-block;
  margin-right: 5px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  color: $footer-icon-color;
}

;@import "sass-embedded-legacy-load-done:2";