/* Device Width--Resolution Settings */

/* Extra Small Screen (or) Phone */
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

/* Small Screen (or) Tablet */
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

/* Medium Screen (or) Desktop */
$screen-md:                  960px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

/* Large Screen (or) Wide Desktop */
$screen-lg:                  1280px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

/* So media queries don't overlap when required */
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


/* Site-wide color's settings */

/* Primary Colors */
$midnight-blue: #011B39;
$royal-blue: #4458DA;
$solitude-light: #F1F2F6;
$pumpkin: #FF6F17;
$brunt-orange: #FF7E2F;
$sky-blue: #5696C3;

/* Secondary Colors */
$dark-green: #010615;
$endeavour: #233293;
$rock-blue: #A1A9C3;
$mischka: #B2B4BA;
$solitude-dark: #DADDE6;

/* Text Colors */
$text-black: #000000;
$text-midnight-blue: #011B39;
$text-chambray: #4C527A;
$text-rock-blue: #8D94BF;
$text-kelly-green: #5EA60F;
$text-grey-chateau: #999BA1;
$text-white: #FFFFFF;

$body-bg-color: #f0f2f5;
$border-bottom-color: #e8e8e8;
$error-color: #d50000;


/* Site-wide Typography */

/* Font-Family */
$font-family: Roboto, Arial, sans-serif;

/* Heading */
$HeadingL1: normal 40px $font-family;
$HeadingL2: normal 32px $font-family;
$HeadingL3: normal 18px $font-family;

/* Paragraph */
$paragraphL1: normal 18px $font-family;
$paragraphL2: normal 16px $font-family;
$paragraphL3: normal 14px $font-family;

/* Text Label */
$label: normal 12px $font-family;


/* Header */
$mobile-menu-icon-color: $brunt-orange;
$mobile-menu-drawer-color: $sky-blue;
$mobile-menu-close-button: $text-white;
$menu-active-link: $brunt-orange;

/* Forms */
$placeholder-text: #a1a9c3;
$input-background: #f1f2f6;


/* Site-wide Settings */
$navbar-color: $sky-blue;
$footer-icon-color: $text-white;

;@import "sass-embedded-legacy-load-done:6";