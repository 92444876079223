/**
* use component name as prefix and before class name and separate it using '-'
* Example ".my-component-classname"
*/

@import '../../../assets/styles/partials/variables';

.ant-layout-header {
  background: $navbar-color;
  padding: 0 30px;

  @media screen and (max-width: $screen-xs) {
    padding: 0 20px;
  }
}

.nav-logo {
  margin: 16px 24px 4px 2px;
  float: left;
  background-repeat: no-repeat;
  background-size: 95% auto;
}

.small-logo {
  width: 100px;
  height: 100px;
  @media screen and (max-width: $screen-xs) {
    width: 48px;
    height: 48px;
  }
}
.large-logo {
  width: 200px;
  height: 200px;
}

.medium-logo {
  width: 200px;
  height: 200px;
  @media screen and (max-width: $screen-xs) {
    width: 96px;
    height: 96px;
  }
}

.right-menu {
  float: right;
}

.ant-menu-dark {
  background: $navbar-color;
}

.ant-menu-item-selected {
  background-color: transparent !important;
  color: $menu-active-link !important;
}

.ant-menu-item-active {
  color: $menu-active-link !important;
}

.profile-menu {
  padding-right: 0;
}

.profile-menu.ant-menu-item-selected {
  background-color: transparent !important;
}

.profile {
  width: 72px;
  height: 49px;
  background-image: url(../../../assets/icons/login.svg);
  background-repeat: no-repeat;
  background-size: 60px;
}

.mobile-icon {
  color: $mobile-menu-icon-color;
  font-size: 32px;
}

.menu-drawer {
  .ant-drawer-content {
    background-color: $mobile-menu-drawer-color;
  }
  .menu-list {
    margin-top: 30px;

    .ant-menu-item {
      text-align: center;
    }

    .ant-menu-item-selected {
      background-color: transparent;
    }

    p {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 500;
      color: $text-white;
      text-align: center;
      opacity: 0.5;
      letter-spacing: 0.35px;
      padding: 8px;
    }
  }

  .ant-drawer-close {
    color: $mobile-menu-close-button;
    font-size: 20px;
  }
}

/* Profile Menu */
.ant-dropdown {
  @media screen and (min-width: $screen-md) {
    width: 17%;
  }
}

.ant-dropdown-menu-item {
  padding: 10px;
  text-align: center;
}


/* Mobile and Desktop Menu */
@media screen and (min-width: $screen-xs-max) {
  .mobile-menu {
    display: none;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .web-menu {
    display: none;
  }
}

.rightMenu {
  float: right;
}
