/**
* use component name as prefix and before class name and separate it using '-'
* Example ".my-component-classname"
*/

@import "../../assets/styles/partials/variables";

.refresh-button {
  border: none;
  background-color: $pumpkin;
  color: $text-white;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  border-radius: 2px;
}
