/**
* use component name as prefix and before class name and separate it using '-'
* Example ".my-component-classname"
*/

@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.ant-layout-footer {
  background: #010615;
  color: $text-white;
  .ant-col {
    margin-top: 35px;
  }
}

.footer-title {
  color: $text-white;
  font-family: $font-family;
  font-size: 26px;
  font-weight: 700;
}

.footer-description {
  opacity: 0.6;
  color: #ffffff;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.copyright-info {
  opacity: 0.6;
  color: #ffffff;
  font-family: $font-family;
  font-weight: 400;
  font-size: 14px;
  margin-top: 24px;
}

.footer-page-links, .footer-support-links {
  color: $text-white;
  font-family: $font-family;
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 17px;
  }
  .links {
    opacity: 0.6;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.17px;
    line-height: 32px;
  }
}

.footer-social-icons {
  .facebook {
    @include footer-social;
    background-image: url(../../../assets/icons/facebook.png);
  }
  .twitter {
    @include footer-social;
    background-image: url(../../../assets/icons/twitter.png);
  }
  .google-plus {
    @include footer-social;
    background-image: url(../../../assets/icons/google-plus.png);
  }
}

