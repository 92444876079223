/**
* use component name as prefix and before class name and separate it using '-'
* Example ".my-component-classname"
*/

@import "../../assets/styles/partials/variables";

.tab-container {
  background: #F1F2F6;
  border: none;
  box-shadow: 0 2px 120px 0 rgba(0,0,0,0.14);
  border-radius: 5px;
  min-height: 732px;
  margin: 20px;
  padding: 20px;

  .app-title {
    color: $midnight-blue;
    font-family: $font-family;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.banner-background {
  background: linear-gradient(to bottom, $sky-blue 400px, $body-bg-color 0px);
}

.ant-tabs-nav {
  font-size: 16px;
  color: $text-black;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: $text-black;
  font-size: 16px;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: $text-black;
}

.ant-tabs-ink-bar {
  background-color: $pumpkin;
}

//.ant-tabs-nav,
//.ant-tabs-nav-scroll {
//  width: 100%;
//}
//
//.ant-tabs-tab {
//  width: 50%;
//  text-align: center;
//}
//
//.ant-tabs-nav .ant-tabs-tab {
//  margin-right: 0;
//}
//


